@use "./custom" as *;
.myCollabs {
	color: $main;

	position: relative;
	.generic-card {
		margin-bottom: 5px;
		padding: 5px;
	}
}

.my-collab-section {
	height: 10%;
	@media screen and (max-width: 768px) {
		display: none;
	}
}
