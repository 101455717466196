@use "./custom" as *;

.add-goal {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px;

	.add-goal-button {
		background-color: $main;
		color: $body;
		font-size: 30px;
		padding: 0 10px;
		border: none;
		cursor: pointer;

		&:hover {
			background-color: #45a049;
		}
	}
}

form {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	input,
	textarea,
	select {
		width: 100%;
		padding: 10px;
		margin: 5px 0;
		border: 1px solid #ccc;
		border-radius: 15px;
		box-sizing: border-box;

		&:focus {
			border-color: #4caf50;
		}
	}

	.form-buttons {
		display: flex;
		justify-content: end;
		width: 100%;

		.btn-primary {
			background-color: #4caf50;
			color: white;
			padding: 10px 20px;
			border: none;
			border-radius: 4px;
			cursor: pointer;

			&:hover {
				background-color: #45a049;
			}
		}

		.btn-secondary {
			background-color: #f44336;
			color: white;
			padding: 10px 20px;
			border: none;
			border-radius: 4px;
			cursor: pointer;

			&:hover {
				background-color: #e53935;
			}
		}
	}
}

.ReactModal__Content--after-open {
	@media (max-width: 768px) {
		width: 80%;
	}
}
