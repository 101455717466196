
//my theme colours
$main-darkest: #0F1801;
//$main-darkest: #0F1801;
$light: #DCF9AD;
//$main: #1B4D3E;
$main: #1B4D3E;
$lighter: #C8F286;
$body: #F5FEE7;
$shadows: #d2ddc1c7;
$box-shadow:  0px 3px 20px $shadows;



$Dark-Green: #2E8B57;
$Forest-Green: #228B22;
$Sea-Green: #2E8B57;
$Lime-Green: #00FF00;
$Light-Green: #90EE90;



//white and grey
 
$white:    #fff ;
$gray-100: #f8f9fa ;
$gray-200: #e9ecef ;
$gray-300: #dee2e6 ;
$gray-400: #ced4da ;
$gray-500: #adb5bd ;
$gray-600: #6c757d ;
$gray-700: #495057 ;
$gray-800: #343a40 ;
$gray-900: #212529 ;
$black:    #000 ;



//accordion overwrite
//$accordion-icon-color: $primary;
//$accordion-icon-active-color: $secondary;
$accordion-padding-y: 1.5rem;
$accordion-padding-x: 1.5rem ;
$accordion-icon-width: 1.5rem !default;

//$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>  <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/> </svg>");
//$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>  <path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/> </svg>") !default;
//import bootstrap 5
