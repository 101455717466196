@use "./custom" as *;
.resource-menu {
	position: absolute;
	display: flex;
	cursor: pointer;
	right: 20px;

	.menu {
		display: none;
		position: absolute;
		right: 0;

		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		z-index: 3 !important;
		min-width: 160px;
		margin-top: 5px;
		background: $main;
		height: fit-content;
		overflow: visible;

		li {
			padding: 10px 15px;
			color: white;
			font-size: 14px;
			display: flex;
			align-items: center;
			&:hover {
				background-color: $light;
				color: $main;
				font-weight: bold;
				transition: all 0.5s;
				-webkit-transition: all 0.5s;
				-moz-transition: all 0.5s;
				-ms-transition: all 0.5s;
				-o-transition: all 0.5s;
			}
			svg {
				margin-right: 8px;
			}
		}
	}

	.menu,
	&:focus-within .menu {
		display: block;
		z-index: 999;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
