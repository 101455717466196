.growth-benefits {
    padding: 2rem;
    .growth-benefits-list {
        list-style: none;
        padding-left: 5px;
        margin: 0; 
        .growth-benefit-item {
            display: flex;
            align-items: center; 
            margin: 5px;
             
            .growth--icon {
                width: 20px; 
                height: 20px; 
                margin-right: 1rem;
                // margin-bottom: 0;
            }  
            .growth--text {
                font-size: 16px;
                font-weight: bold;
                margin: 0;
            }
            
        }
      }
      
  }