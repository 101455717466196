@use './custom' as * ;

.register-page {
    display: flex; 
    align-items: center;
    justify-content: center;
    min-height: 80vh; 
    margin: auto;
    .growth-benefits{
      @media screen and (max-width: 768px) {
        display: none;
        
      }
    }
    // @media screen  and (max-width: 767px){
    //   margin: auto;
    // }
  
  .register-form {
    flex: 1; 
    max-width: 400px; 
    padding: 1rem;
    border: 1px solid #ddd;
    align-items: center;
    justify-content: center;
    border: 3px solid $light;
    margin-top: 10px;
    @media screen and (max-width: 784px) {
      border: none;
    }


    @media screen  and (max-width: 767px){
      max-width: 80vw;
      max-height: 75vh;
            margin: auto;
    }
  .form--Buttons{
    display: flex;
    align-items: center;
    justify-content: center;
  }
    .fullName{
        display: flex;
        justify-content: space-between;
    }
  }
  
  .register-form h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    text-align: center; 
  }
  
  .register-form input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem; /* Space between inputs */
    border: 1px solid #ccc;
    border-radius: 15px;
    box-sizing: border-box; /* Ensure padding is included in total width */
  }
  
  
  .register-form button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  .register-image {
    width: 40%; /* Adjust image width */
    max-width: 600px; /* Ensure image does not get too large */
  }
  
  .register-image img {
    width: 100%;
    border-radius: 5px; /* Rounded corners for the image */
  }
}