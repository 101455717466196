// src/styles/Resources.scss
@use "./custom" as *;

.resources {
	width: 400px;
	border-radius: 15px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
	height: 75vh;
	padding: 0px !important;
	position: relative;
	justify-content: center;
	align-items: center;
	align-content: start;
	z-index: 0;

	h1 {
		margin-bottom: 5px;
	}
	p {
		padding: 10px;
	}

	// Responsive adjustments
	@media screen and (max-width: 768px) {
		margin-left: auto;
		margin-right: auto;
		margin: 20px auto;
		width: 90%;
	}

	@media (max-width: 480px) {
		width: 90%;
		height: auto;

		.resource-list,
		ul {
			grid-template-columns: repeat(
				auto-fill,
				minmax(150px, 1fr)
			); // Adjust grid layout for smaller screens
			height: auto; // Adjust height for a better mobile experience
		}
	}
}

.resource-list {
	list-style-type: none;
	padding: 10px;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}

.resource-item {
	width: 100px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	background-color: $light;
	border-radius: 15px;
	padding: 5px;
	position: relative;
	transition: transform 0.3s ease;

	&:hover {
		height: fit-content;
		transform: translateY(-5px);
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

		.resource-name {
			overflow: visible;
			white-space: wrap;
		}
	}

	.r-menu-icon {
		position: absolute;
		right: 5px;
		top: 5px;
		cursor: pointer;
		z-index: 2;
	}
}

.resource-icon {
	font-size: 2rem;
	flex: 2;
}

.resource-name {
	word-wrap: break-word !important;
	word-break: break-word !important;
	margin-top: 3px;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 0.7rem;
}

.custom-file-upload {
	display: inline-block;
	padding: 6px 12px;

	cursor: pointer;

	&:hover {
		background-color: #ccc;
	}
}
