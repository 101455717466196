@use "./custom" as *;

.loading {
	$colors: #7eff7e, #89e089, #46b446, #0fba0f, #008000;
	display: flex;

	.dot {
		position: relative;
		width: 1em;
		height: 1em;
		margin: 0.8em;
		border-radius: 50%;

		&::before {
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			background: inherit;
			border-radius: inherit;
			animation: wave 2s ease-out infinite;
		}

		@for $i from 1 through 5 {
			&:nth-child(#{$i}) {
				background: nth($colors, $i);

				&::before {
					animation-delay: $i * 0.2s;
				}
			}
		}
	}
}

@keyframes wave {
	50%,
	75% {
		transform: scale(2.5);
	}

	80%,
	100% {
		opacity: 0;
	}
}

.loading1 {
	width: auto;
	margin: auto;
	padding: 8px;
	aspect-ratio: 1;
	border-radius: 50%;
	background: #25b09b;
	--_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
	-webkit-mask: var(--_m);
	mask: var(--_m);
	-webkit-mask-composite: source-out;
	mask-composite: subtract;
	animation: l3 1s infinite linear;
}
@keyframes l3 {
	to {
		transform: rotate(1turn);
	}
}

.main-loader {
	height: 10px;
	z-index: 4;
	background: linear-gradient(
		300deg,
		#0f4a2c,
		#32654a,
		#dded09,
		#09ede8,
		#ed09de
	);
	background-size: 300% 300%;
	animation: gradient-animation 5s ease infinite;
}

@keyframes gradient-animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.frame {
	position: relative;
	top: 50%;
	left: 50%;
	margin-top: -200px;
	margin-left: -200px;
	width: 100%;
	height: 100%;

}

.center {
	position: relative;
	width: 220px;
	height: 220px;
	top: 90px;
	left: 90px;
}

.dot-1 {
	position: absolute;
	z-index: 3;
	width: 30px;
	height: 30px;
	top: 95px;
	left: 95px;
	background: #fff;
	border-radius: 50%;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation: jump-jump-1 2s cubic-bezier(0.21, 0.98, 0.6, 0.99) infinite
		alternate;
	animation: jump-jump-1 2s cubic-bezier(0.21, 0.98, 0.6, 0.99) infinite
		alternate;
}

.dot-2 {
	position: absolute;
	z-index: 2;
	width: 60px;
	height: 60px;
	top: 80px;
	left: 80px;
	background: $light;
	border-radius: 50%;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation: jump-jump-2 2s cubic-bezier(0.21, 0.98, 0.6, 0.99) infinite
		alternate;
	animation: jump-jump-2 2s cubic-bezier(0.21, 0.98, 0.6, 0.99) infinite
		alternate;
}

.dot-3 {
	position: absolute;
	z-index: 1;
	width: 90px;
	height: 90px;
	top: 65px;
	left: 65px;
	background: $main;
	border-radius: 50%;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation: jump-jump-3 2s cubic-bezier(0.21, 0.98, 0.6, 0.99) infinite
		alternate;
	animation: jump-jump-3 2s cubic-bezier(0.21, 0.98, 0.6, 0.99) infinite
		alternate;
}

@keyframes jump-jump-1 {
	0%,
	70% {
		box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.2);
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	100% {
		box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.3);
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes jump-jump-2 {
	0%,
	40% {
		box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.2);
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	100% {
		box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.3);
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes jump-jump-3 {
	0%,
	10% {
		box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.2);
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	100% {
		box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.3);
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}


/* From Uiverse.io by abrahamcalsin */ 
.dot-spinner {
	--uib-size: 2.8rem;
	--uib-speed: .9s;
	--uib-color: #183153;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: var(--uib-size);
	width: var(--uib-size);
  }
  
  .dot-spinner__dot {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	width: 100%;
  }
  
  .dot-spinner__dot::before {
	content: '';
	height: 20%;
	width: 20%;
	border-radius: 50%;
	background-color: var(--uib-color);
	transform: scale(0);
	opacity: 0.5;
	animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
	box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
  }
  
  .dot-spinner__dot:nth-child(2) {
	transform: rotate(45deg);
  }
  
  .dot-spinner__dot:nth-child(2)::before {
	animation-delay: calc(var(--uib-speed) * -0.875);
  }
  
  .dot-spinner__dot:nth-child(3) {
	transform: rotate(90deg);
  }
  
  .dot-spinner__dot:nth-child(3)::before {
	animation-delay: calc(var(--uib-speed) * -0.75);
  }
  
  .dot-spinner__dot:nth-child(4) {
	transform: rotate(135deg);
  }
  
  .dot-spinner__dot:nth-child(4)::before {
	animation-delay: calc(var(--uib-speed) * -0.625);
  }
  
  .dot-spinner__dot:nth-child(5) {
	transform: rotate(180deg);
  }
  
  .dot-spinner__dot:nth-child(5)::before {
	animation-delay: calc(var(--uib-speed) * -0.5);
  }
  
  .dot-spinner__dot:nth-child(6) {
	transform: rotate(225deg);
  }
  
  .dot-spinner__dot:nth-child(6)::before {
	animation-delay: calc(var(--uib-speed) * -0.375);
  }
  
  .dot-spinner__dot:nth-child(7) {
	transform: rotate(270deg);
  }
  
  .dot-spinner__dot:nth-child(7)::before {
	animation-delay: calc(var(--uib-speed) * -0.25);
  }
  
  .dot-spinner__dot:nth-child(8) {
	transform: rotate(315deg);
  }
  
  .dot-spinner__dot:nth-child(8)::before {
	animation-delay: calc(var(--uib-speed) * -0.125);
  }
  
  @keyframes pulse0112 {
	0%,
	100% {
	  transform: scale(0);
	  opacity: 0.5;
	}
  
	50% {
	  transform: scale(1);
	  opacity: 1;
	}
  }
  