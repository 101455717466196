@use "./custom" as *;
.task-list {
	width: 400px;
	box-shadow: $box-shadow;
	height: 75vh;
	padding: 0px !important;
	position: relative;
	flex-shrink: 0 !important;
	border-radius: 15px;
	cursor: pointer;
	@media screen and (max-width: 768px) {
		margin: 20px auto;
		width: 90%;
	}
	.title {
		position: absolute;
		width: 30%;
		background-color: $main-darkest;
		color: white;
		text-align: center;
		padding: 10px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		transform: translateY(-50%);
	}

	.task-cards {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		align-items: center;
		overflow-y: scroll;
		margin-top: 10px;
		height: 400px;
		padding-top: 10px;
	}

	.task-card {
		width: 90%;
		height: 80px;
		display: flex;
		flex-direction: column;
		padding: 10px;
		align-items: center;
		align-content: center;
		border: 1px solid #ddd;
		border-radius: 15px;
		background-color: #fff;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		transition: transform 0.3s ease;
		cursor: pointer;
		.task-info__name {
			margin: 0;
		}

		.task--details1 {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0;
			.growth--icon {
				width: 20px;
				height: 20px;
				margin-right: 1rem;
			}

			p {
				margin: 0; /* Removes default margin from paragraphs */
			}

			.task-info {
				display: flex;
				flex-direction: column;
				align-items: start;
				align-content: start;
				justify-content: space-between;
				padding: 5px;
				width: 90%;
				color: $main-darkest;
				font-size: 1.2rem;
				font-weight: 500;
				margin: auto;
				.task-info1 {
					text-align: left;
					.task-info__description {
						font-size: 0.5em;
						margin: 0;
						padding: 0;
						color: $main;
						font-weight: 500;
					}
				}

				.task-info2 {
					display: flex;
					flex-direction: row;
					align-items: center;
					align-content: center;
					margin: 0;
					padding: 0px;
					font-weight: bold;
					font-size: 0.5em;
					li:first-child {
						list-style: none;
						margin-left: 0px;
						margin-right: 20px;
					}
					li {
						margin-left: 10px;
						align-content: center;
						align-items: center;
						margin-right: 20px;
					}

					li::marker {
						color: $main-darkest;
						font-size: 0.5rem;
					}
				}
			}
		}

		.task--details2 {
			display: flex;
			flex-direction: row;
			align-items: center;
			align-content: center;
			width: 90%;
			height: 15px;
			justify-content: space-between;
			font-size: 0.7rem;
			font-weight: 500;
			margin-top: 0;
		}
	}

	.task-card:hover {
		transform: translateY(-5px);
	}

	.tasks-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
	}

	.taskTabs {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		justify-content: left;
		margin: 10px;
		font-size: large;
		font-weight: bolder;
		color: rgb(168, 186, 168);
		.selected {
			color: $main-darkest;
			padding: 5px;
			border-radius: 7px;
			font-size: larger;
		}
	}
}

.task-progress {
	align-items: center;
	justify-content: center;
	align-content: center;
	width: 80px;
	height: 80px;
}

.card-progress {
	align-items: center;
	justify-content: center;
	align-content: center;
	width: 80px;
	height: 80px;
}
