@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.socialIcon {
		@apply w-11 border-[1px] border-blue-400 border-solid h-11 rounded-full px-2 py-1 text-pink-500  hover:text-blue-400 cursor-pointer transition duration-200 hover:animate-pulse;
	}
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f5fee7;
}

html {
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
