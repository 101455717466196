@use './custom' as * ;

.main--aside{
text-align: left;
position: fixed;
height: 85%; 
width: 200px; 
.aside {
    height: 80%;
    top: 100px;
    left: 10px;
    background-color: $light;
    
    box-sizing: border-box; 
    align-items: start;
    text-align: left;
    padding: 10px;
    margin-bottom: 100px;
  
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
  
      a {
        text-decoration: none;
        color: #333;
        display: block; 
        padding: 5px; 
        font-weight: bold;
      }
    }
  
    h2,
    h3 {
      margin-bottom: 10px;
    }
  }
  .version{
    position: relative;
    bottom: 0;
    left: 10px;
  }
}
  .aside h2 {
    font-weight: bold; // Bold text for Home
  }
  
  .main-content {
    margin-left: 200px;
    padding: 20px;
  }
  