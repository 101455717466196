// styles.scss

// Variables for consistent styling
$primary-color: #007bff;
$error-color: #dc3545;
$success-color: #28a745;
$button-color: #007bff;
$delete-icon-color: #dc3545;
$border-radius: 15px;

.clone-items-container {
	width: 95%;
	margin: 20px auto;
	padding: 20px;
	border: 1px solid #ddd;
	border-radius: $border-radius;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 500px) {
		width: 90%;
	}
	.btn--outline {
		white-space: nowrap;
	}

	

	.new-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;
div{
	display: flex;
		flex-direction: row;
}
		@media screen and (max-width: 500px) {
			flex-direction: column;
			justify-content: left;
			align-items: start;
		}
	}

	// input[type="text"] {
	// 	width: calc(100% - 70px);
	// 	padding: 5px;
	// 	margin-right: 10px;
	// 	border: 1px solid #ccc;
	// 	border-radius: $border-radius;
	// 	font-size: 16px;
	// }

	.error-message {
		color: $error-color;
		margin-top: 10px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 20px 0 0 0;
		display: flex;
		flex-wrap: wrap;
		gap: 10px;

		li {
			display: flex;
			align-items: start;
			align-content: start;
			justify-content: space-between;
			padding: 5px;
			margin-bottom: 5px;
			border: 1px solid #ccc;
			border-radius: $border-radius;
			background-color: #f9f9f9;
			gap: 5px;

			span {
				flex-grow: 1;
				font-size: 16px;
			}

			.checklist-name-and-desc {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				width: 100%;
				margin-bottom: 10px;
			}
			
			.checklist-name {
				font-size: 1.2em;
				font-weight: bold;
				margin-bottom: 5px;
			}
			
			.checklist-desc {
				font-size: 0.9em;
				color: #666;
			}

			button.delete-btn {
				background: none;
				border: none;
				cursor: pointer;
				color: $delete-icon-color;
				font-size: 10px;
				transition: color 0.3s ease;
				border-radius: 15px;
				padding: 3px;
				

			}
		}
	}
}
