@use "./custom" as *;

////////
///
///
//
.NavbarOnSm {
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	background: rgba(2, 37, 21, 0.91);
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(8.6px);
	-webkit-backdrop-filter: blur(8.6px);
	border: 1px solid rgba(2, 37, 21, 0.3);
	display: grid;
	align-items: center;
	left: 0;
	transition: 0.3s ease-in-out;
}

.opacity-on {
	opacity: 1;
	top: 0;
}
.opacity-off {
	opacity: 0;
	top: -100%;
}
.icon {
	background: transparent;
	font-size: 2rem;
	cursor: pointer;
	outline: none;
	color: $main-darkest;
	position: absolute;
	top: 2rem;
	right: 2.5rem;
}

.close-icon {
	color: whitesmoke;
}
.sidebar-menu {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(4, 80px);
	text-align: center;
	margin-bottom: 4rem;
	.menu-items {
		display: flex;
		flex-direction: column;
		align-content: space-around;
		align-items: center;
		justify-content: center;
		.menu-item {
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			color: #fff;
			cursor: pointer;
			transition: 0.2s ease-in-out;
			margin-top: 10px;
		}
	}
}
.sidebar-links {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 1.5rem;
	text-decoration: none;
	list-style: none;
	cursor: pointer;
	transition: 0.2s ease-in-out;
}

@media screen and (max-width: 480px) {
	.sidebar-menu {
		grid-template-rows: repeat(4, 60px);
	}
}
.button-wrap {
	display: flex;
	justify-content: center;
}

.main-logo {
	display: flex;
	align-items: center;
	.logo {
		width: 50px;
		height: auto;
		margin-right: 0;
	}
	.logo-text {
		font-size: 1.2rem;
		color: $main-darkest;
		margin-left: 0;
	}
}
