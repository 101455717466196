@use "./custom" as *;

.benefits {
	margin-top: 20%;
	margin-bottom: 20px;
	justify-content: center !important;
	hr {
		margin: 20px;
	}
	.benefits__content {
		article {
			font-weight: bold;
		}
		font-size: 1rem;
		color: $main-darkest;
	}
	@media (max-width: 768px) {
		.benefits__content {
			article {
				font-weight: bold;
			}
			font-size: 4dvw;
		}
	}
	.benefits_info {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin-top: 5%;
		.benefits__image {
			// box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
			margin-bottom: 20px;
			border-radius: 15px;
			@media only screen and (max-width: 600px) {
				box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
				margin-bottom: 20px;
				width: 80%;
			}
		}

		@media (min-width: 768px) {
			flex-direction: row;
			.benefits__image {
				width: 40%;
				margin-bottom: 0;
			}
		}
	}
}
