@use "./custom" as *;

* {
	margin: 0;
	padding: 0;
	text-decoration: none;
}

.main--aside {
	text-align: left;
	position: fixed;
	height: 85%;
	width: 200px;
	align-content: space-between !important;

	.version {
		margin-top: 50px !important;
		position: relative;
		bottom: 0;
		left: 10px;
		margin-top: auto;
		color: $light;
		z-index: 1;
	}
}
.sidebar {
	background-color: $main;

	color: #fff;
	height: 100%;

	border-bottom-right-radius: 15px;
	border-top-right-radius: 15px;
	background-color: #dbe900;
	background: linear-gradient(
		130deg,
		rgba(3, 53, 11, 0.966) 0%,
		rgba(88, 141, 3, 0.63) 100%
	);
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px, rgba(0, 0, 0, 0.1) 0px 6px 6px;
	.top-section {
		display: flex;
		align-items: center;
		padding: 15px;
	}

	.logo {
		font-size: 10px;
	}

	.bars {
		display: flex;
		font-size: 25px;
		margin-left: 50px;
		/* padding-left: 40px; */
	}

	.link {
		display: flex;
		color: #fff;
		padding: 10px 20px;
		gap: 16px;
		transition: all 0.5s;
		-webkit-transition: all 0.5s;
		-moz-transition: all 0.5s;
		-ms-transition: all 0.5s;
		-o-transition: all 0.5s;
	}

	.link:hover {
		background-color: $light;
		color: #000;
		transition: all 0.5s;
		-webkit-transition: all 0.5s;
		-moz-transition: all 0.5s;
		-ms-transition: all 0.5s;
		-o-transition: all 0.5s;
	}
	.body-section {
		background-color: red;
		margin-left: 70px;
		width: auto;
	}
	.active {
		background-color: $body;
		color: #000;
		border-bottom-right-radius: 15px;
		border-top-right-radius: 15px;
	}

	.icondd,
	.link-text {
		font-size: 18px;
	}

	.container {
		position: relative;
		width: 100%;
	}
}
/* .sidebar {
	position: absolute;
	top: 0;
	left: 0;

	z-index: 1000;
	transition: width 0.3s ease;
	background-color: #000;
	color: #ff1313;
} */
