@use "./custom" as *;

.checklist-box {
	width: 90%;

	background-color: $light;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 15px;
	font-family: Arial, sans-serif;
	@media screen and (max-width: 768px) {
		margin-bottom: 20px;
		width: 90%;
	}
}

.checklist-box h2 {
	text-align: center;
	color: #333;
}

.checklist-box ul {
	list-style-type: none;
	padding: 0;
}

.checklist-box li {
	padding: 10px;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.checklist-box li:last-child {
	border-bottom: none;
}

.status {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	padding-top: 10px;
	border-top: 1px solid #ddd;
}

.status-item {
	flex: 1;
	text-align: center;
}

.status-item:not(:last-child) {
	border-right: 1px solid #ddd;
}
.task-metadata {
	margin-bottom: 20px;
}

.due-date {
	font-size: 16px;
	color: #333;
}

.task-actions {
	display: flex;
	align-items: center;
	gap: 10px;
}

.input-url {
	padding: 8px;
	font-size: 14px;
	border: 1px solid #ccc;
	border-radius: 4px;
	flex: 1; /* Makes the input take the available width */
}

.btn {
	padding: 8px 12px;
	font-size: 14px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.btn-primary {
	background-color: #007bff;
	color: #fff;
	transition: background-color 0.3s ease;
}

.btn-primary:hover {
	background-color: #0056b3;
}

.checklist-and-memebersdone {
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0 auto;

	
	align-items: center;
	align-content: start;
	@media screen and (max-width: 768px) {
		margin: 20px auto;
		width: 90%;
	}
}
